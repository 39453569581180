<script setup>
import ProfileDetails from "../components/ProfileDetails.vue"
import { useDictionaryStore } from '../stores/DictionaryStore.js';
const dictionaryStore = useDictionaryStore();
</script>
<script>
export default {
	data() {
		return {
		}
	},
	mounted(){
		if (Object.keys(this.dictionaryStore.dictionary).length === 0){
			this.dictionaryStore.GetDictionaryData();		
		}
	},
	methods: {
	}
}
</script>
<template>
		<div class="row mb-3">
			<div class="col-1"></div>
			<div class="col-3 text-left">
				<a href="/"><img src="../assets/images/logo.svg" class="img-fluid" alt=""></a>				
			</div>
			<div class="col-2"></div>
			<!-- <div class="col-6 text-end">
				<img src="../assets/images/decor-right.png" class="img-fluid" alt="">
			</div> -->
		</div>
	<main>
		<div class="display">
			<div class="container">
				<ProfileDetails :profile-id="$route.params.id"></ProfileDetails>
			</div>	
		</div>
		
	</main>
</template>

<style lang="scss">

</style>
