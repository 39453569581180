<script setup>
import { useDictionaryStore } from '../stores/DictionaryStore.js';
			const dictionaryStore = useDictionaryStore();
</script>
<script>
	export default {
		// setup() {
		// 	const dictionaryStore = useDictionaryStore();
		// 	return {
		// 		dictionaryStore,
		// 	}
		// },
		props: ['filterQuery'],
		data(){
			return{
				isActive: false,
				expVal: 1,
			}
		},
		methods: {
			ShowSearchValues(key){
				return this.filterQuery.getAll(key);
			}
		},
		mounted(){
			this.expVal = (this.filterQuery.get('experience') || 1);
		},
		computed: {
			years: function(){
				var str = "Более "+ this.expVal +" лет";
				if (this.expVal == 1) {
				str = 'Более 1 года';
				}
			return str;
			},
		}
	}
</script>
<template>
	<div class="profile-list__filters profile-filters" :class="{active: isActive}">
		<div class="profile-filters__content-wrapper mh-100 w-100 overflow-auto">
			<form action="/profiles/" method="get">
				<div class="container my-4">
					<div class="row">
						<div class="col-12 small">
							<div class="mb-3">
								<h5 class="text-primary">Выберите язык:</h5>
								<div class="w-75 overflow-auto border border-info p-1" style="max-height: 300px;">
									<div class="form-check" v-for="language, index in dictionaryStore.dictionary.languages">
										<input class="form-check-input" type="checkbox" name="language" :value="language.id" :id="'languageCheck-' + language.id" :checked="ShowSearchValues('language').includes(String(language.id))">
										<label class="form-check-label" :for="'languageCheck-' + language.id">
											{{ dictionaryStore.GetTranslation('languages', language.id).title }}
										</label>
									</div>
								</div>
							</div>
							<div class="mb-3">
								<h5 class="text-primary">Выберите регион:</h5>
								<div class="w-75 overflow-auto border border-info p-1" style="max-height: 300px;">
									<div class="form-check" v-for="location, index in dictionaryStore.dictionary.locations">
										<input class="form-check-input" type="checkbox" name="location" :value="location.id" :id="'regionCheck-' + location.id" :checked="ShowSearchValues('location').includes(String(location.id))">
										<label class="form-check-label" :for="'regionCheck-' + location.id">
											{{ dictionaryStore.GetTranslation('locations', location.id).title }}
										</label>
									</div>
								</div>
							</div>
							<div class="mb-3">
								<h5 class="text-primary">Выберите области деятельности:</h5>
								<div class="w-75 overflow-auto border border-info p-1" style="max-height: 300px;">
									<div class="form-check" v-for="fow, index in dictionaryStore.dictionary.fieldsofwork">
										<input class="form-check-input" type="checkbox" name="field_of_work_type" :value="fow.id" :id="'fowCheck-' + fow.id" :checked="ShowSearchValues('field_of_work_type').includes(String(fow.id))">
										<label class="form-check-label" :for="'fowCheck-' + fow.id">
											{{ dictionaryStore.GetTranslation('fieldsofwork', fow.id).title }}
										</label>
									</div>
								</div>
							</div>
							<div class="mb-3">
								<h5 class="text-primary">Опыт взаимодействия со СМИ:</h5>
								<div class="w-75 overflow-auto border border-info p-1" style="max-height: 300px;">
									<div class="form-check" v-for="medex in dictionaryStore.dictionary.mediaexpirience">
										<input class="form-check-input" type="checkbox" name="media_experience" :value="medex.id" :id="'mediaexpiriensCheck-' + medex.id" :checked="ShowSearchValues('media_experience').includes(String(medex.id))">
										<label class="form-check-label" :for="'mediaexpiriensCheck-' + medex.id">
											{{ dictionaryStore.GetTranslation('mediaexpirience', medex.id).title }}
										</label>
									</div>
								</div>
							</div>
							<div class="mb-3">
								<h5 class="text-primary">Опыт занятия правозащитной	деятельностью:</h5>
								<input type="range" class="form-range w-75" name="experience" min="1" max="10" id="experienceRange" v-model="expVal"><br />
								<span for="experienceRange" class="form-label text-primary mt-2">{{ years }}</span>
							</div>
							<div class="mb-3">
								<h5 class="text-primary">Поиск по имени:</h5>
								<input type="text" class="form-control w-75" name="search" id="textSearch" placeholder="Введите строку для поиска">
							</div>
							<div class="row">
							  <div class="d-grid gap-2 col-6">
							    <button class="btn btn-lg btn-primary mt-2" type="submit">Найти</button>
							  </div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="profile-filters__button bg-primary" @click="isActive = !isActive">
		</div>
	</div>
</template>
<style lang="scss" scoped>
.profile-filters {
	--closed-gap:10px;
	--mob-open-gap:32px;
	position: absolute;
	right: calc(100% - var(--closed-gap));
	width: calc(500px);
	@media screen and (max-width: 700px) {
		width: calc(100% - var(--mob-open-gap));
	}
	top: 10px;
	bottom: 10px;
	background: #fff;
	border-top: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;
	border-radius: 0 15px 15px 0;
	z-index: 5;
	transition: all 0.5s;
	&.active{
		transform: translateX(calc(100% - var(--closed-gap)));
	}
	&:not(.active){
		&>.profile-filters__button {
			
			--arrow-rotation: 0deg;
		}
	}
	&__content-wrapper {
		// width: 100%;
		// max-height: 100%;
		// overflow-y: auto;

	}
	&__button {
		--btn-size: 60px;
		--btn-spacing: 30%;

		--arrow-offset:0px;
		--arrow-rotation: 180deg;

		position: absolute;
		width: var(--btn-size);
		height: var(--btn-size);
		border-radius: 50%;
		top: calc(50% - (var(--btn-size)/2));
		right: calc(var(--btn-size) * -0.5);
		border: 1px solid #ccc;
		//background: #fff;
		overflow: hidden;
		cursor: pointer;
		&:before{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			//background: #eee;
			transition: all 0.5s;
			transform: scale(0);
			border-radius: 50%;
		}
		&:after{
			content: "";
			position: absolute;
			border-right: 2px solid #ccc;
			border-bottom: 2px solid #ccc;
			left: calc(var(--btn-spacing));
			right: calc(var(--btn-spacing));
			bottom: var(--btn-spacing);
			top: var(--btn-spacing);
			transition: all 0.5s;
			transform:

				rotate(calc(-45deg + var(--arrow-rotation,0)))
				translate(var(--arrow-offset,0),var(--arrow-offset,0))
				translate(calc(var(--btn-spacing)/-2), calc(var(--btn-spacing)/-2))
			;
		}
		&:hover{
			--arrow-offset:5px;
			&:before{
				transform: scale(1);
			}
			
		}
	}
}
</style>
