<script>
	import { RouterLink, RouterView } from 'vue-router'
	export default {}
</script>
<template>
	<header>
		<nav class="navbar navbar-expand-lg bg-light">
			<div class="container-fluid">
				<RouterLink class="navbar-brand" to="/">HD</RouterLink>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<li class="nav-item">
							<RouterLink class="nav-link active" to="/">Главная</RouterLink>
						</li>
						<li class="nav-item">
							<RouterLink class="nav-link" to="/logout">Выход</RouterLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
</template>

<style lang="scss" scoped>
	
</style>
