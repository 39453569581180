import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '../stores/AuthStore.js'

import LogoutView from '../views/LogoutView.vue'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import InitialView from '../views/InitialView.vue'
import DetailView from '../views/DetailView.vue'
import PageNotFoundView from '../views/PageNotFoundView.vue'


const router = createRouter({
	history: createWebHistory('/'),
	routes: [
		{
			path: '/login',
			name: 'login',
			component: LoginView,
			meta: {
				requiresAuth: false

			},
			beforeEnter: (to, from) => {
				const authStore = useAuthStore();
				if(authStore.isLoggedIn){
					//return from;
					return true;

				}
				return true;
			},
		},
		{
			path: '/',
			name: 'initial',
			component: InitialView,
			meta: {
				requiresAuth: false
			},
		},
		{
			path: '/profile/:id',
			name: 'detail',
			component: DetailView,
			meta: {
				requiresAuth: false
			}	
		},
		{
			path: '/profiles',
			name: 'home',
			component: HomeView,
			meta: {
				requiresAuth: false
			}	
		},
		{ path: '/:pathMatch(.*)*', component: PageNotFoundView }
	]
});
// router.beforeEach((to, from) => {
// 	const authStore = useAuthStore();
// 	if(!authStore.isLoggedIn){
// 		authStore.TryReadToken();
// 	}
// 	if (to.meta.requiresAuth && !authStore.isLoggedIn) {
// 		return { name: 'login' };
// 	}
// 	return true;
// });
export default router
