<script setup>
import { useDictionaryStore } from '../stores/DictionaryStore.js';
			const dictionaryStore = useDictionaryStore();
</script>
<script>
	export default {
		setup() {
			const dictionaryStore = useDictionaryStore();
			return {
				dictionaryStore,
			}
		},
		data(){
			return{
			}
		},
		mounted(){
		}
	}
</script>
<template>
<form action="/profiles/" method="get">
	<div class="row">
		<div class="col-12 col-md-6">
			<h5 class="mb-0">Выберите области деятельности:</h5>
			<div class="small mb-2">(можно выбрать несколько значений)</div>
			<div class="form-check" v-for="fow in dictionaryStore.dictionary.fieldsofwork">
				<input class="form-check-input" type="checkbox" name="field_of_work_type" :value="fow.id" :id="'fowCheck-' + fow.id">
				<label class="form-check-label" :for="'fowCheck-' + fow.id">
					{{ dictionaryStore.GetTranslation('fieldsofwork', fow.id).title }}
				</label>
			</div>
		</div>
		<div class="col-12 col-md-6">
			<div class="mb-3">
				<h5 class="mb-0">Выберите регион:</h5>
				<div class="small mb-2">(можно выбрать несколько значений)</div>
				<div class="form-check" v-for="location, index in dictionaryStore.dictionary.locations">
					<input class="form-check-input" type="checkbox" name="location" :value="location.id" :id="'regionCheck-' + location.id">
					<label class="form-check-label" :for="'regionCheck-' + location.id">
						{{ dictionaryStore.GetTranslation('locations', location.id).title }}
					</label>
				</div>
			</div>
			<div class="mb-3">
				<h5 class="mb-0">Выберите язык:</h5>
				<div class="small mb-2">(можно выбрать несколько значений)</div>
				<div class="form-check" v-for="language, index in dictionaryStore.dictionary.languages">
					<input class="form-check-input" type="checkbox" name="language" :value="language.id" :id="'languageCheck-' + language.id">
					<label class="form-check-label" :for="'languageCheck-' + language.id">
						{{ dictionaryStore.GetTranslation('languages', language.id).title }}
					</label>
				</div>
			</div>
			<div class="mb-3">
				<h5>Текстовый поиск:</h5>
				<input type="text" class="form-control" name="search" id="textSearch" placeholder="Введите строку для поиска">
			</div>
		</div>
	</div>
	<div class="row flex-row-reverse">
	  <div class="d-grid gap-2 col-6">
	    <button class="btn btn-lg btn-primary" type="submit">Найти</button>
	  </div>
	</div>
</form>
</template>
<style lang="scss" scoped>

</style>