<script>
	import { useAuthStore } from '../stores/AuthStore.js';
	export default{
		setup(){
			const authStore = useAuthStore()

			return {
				authStore,

			}
		},
		mounted(){
			this.authStore.Logout();
			this.$router.replace({name:"login"});
		}
	}
</script>
<template></template>