<script setup>
import ProfileCard from "../components/ProfileCard.vue"
import {axios, api} from '../App.vue';
import { useCurrentListPathStore } from '../stores/CurrentListPathStore.js';
const currentList = useCurrentListPathStore();
</script>
<script>
	export default {
		props: ['filterQuery'],
		data(){
			return {
				profilesList : {},

			}
		},
		methods: {
			GetProfilesList(){
				let data = new FormData();
				data.append('page', this.pageNumber);
				axios
					.get(api.baseURL + "/api/profiles/?" + this.currentPath)
					.then(response => {
						this.profilesList = response.data;
				});
			},
			GetPagePath(page) {
				const path = new URLSearchParams(this.currentPath);
				path.set('page',page);
				return '/profiles/?' + path.toString();
			}
		},
		computed: {
			currentPath: function (){
				return this.filterQuery.toString();
			}
		},
		mounted(){
			this.currentList.path = this.currentPath;
			this.GetProfilesList();
		},
	}
</script>
<template>
	<div class="container" v-if="profilesList.total_objects > 0">
		<div class="row pb-3">
			<ProfileCard v-for="profile in profilesList.results" :data-profile="profile"></ProfileCard>
		</div>
		<div class="row">
			<nav aria-label="Page navigation example" class="">
			  <ul class="pagination justify-content-center">
			    <li class="page-item" v-for="page in profilesList.total_pages"><a class="page-link" :href="GetPagePath(page)">{{ page }}</a></li>
			  </ul>
			</nav>
		</div>
	</div>
	<div class="nothing-found" v-if="profilesList.total_objects == 0">
		<h6>Ничего не найдено, попробуйте изменить запрос</h6><a href="/">назад</a>

	</div>
</template>
<style lang="scss" scoped>
.nothing-found {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.profile-card-list{
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-top: 75px;
}
</style>
