<script>
	import { useDictionaryStore } from '../stores/DictionaryStore.js';
	import { RouterLink, RouterView } from 'vue-router'
	export default {
		setup() {
			const dictionaryStore = useDictionaryStore();

			return {
				dictionaryStore,

			}
		},
		props: ['dataProfile', 'currentPath'],
		data(){
			return{
				percentage: 50,
			}
		},
		computed: {
			years: function(){
				var str = "более 10 лет";
				if (this.dataProfile['expirience'] == 1) {
				    str = this.dataProfile['expirience'] + ' год';
				}
				if (this.dataProfile['expirience'] > 1 && this.dataProfile['expirience'] < 5) {
				    str = this.dataProfile['expirience'] + ' года';
				}
				if (this.dataProfile['expirience'] == 0 || (this.dataProfile['expirience'] > 4 && this.dataProfile['expirience'] < 10)) {
				    str = this.dataProfile['expirience'] + ' лет';
				}
			return str;
			},
		},
	}
</script>
<template>
<div class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 mb-5">
	<div class="card">
		<img :src="dataProfile.avatar" class="card-img-top" alt="">
		<div class="card-body">
			<h5 class="card-title">{{ dataProfile.last_name }}</h5>
			<h5 class="card-title">{{ dataProfile.first_name }}</h5>
			<p class="card-text fw-bold card-text-height">Регион:&nbsp;
				<span v-for="location, index in dataProfile.location" v-if="dictionaryStore.dictionary.locations">
					<span v-if="index>0">, </span><span>{{ dictionaryStore.GetTranslation('locations', location).title }}</span>
				</span>
			</p>
		</div>
			<ul class="list-group list-group-flush">
				<li class="list-group-item">
					<span class="fw-bold">Опыт работы: </span>
					<span>{{ years }}</span>
				</li>
				<li class="list-group-item">
					<span>Языки: </span>
					<span class="mx-1" v-for="lang in dataProfile.language" v-if="dictionaryStore.dictionary.languages">
						<img width="20" :src="dictionaryStore.dictionary.languages[dictionaryStore.GetIndex('languages', lang)].icon" :alt="dictionaryStore.GetTranslation('languages', lang).title">
					</span>
				</li>
			</ul>
			<div class="card-body text-end">
				<router-link class="btn btn-primary btn-sm" :to="{ name: 'detail', params: { id:dataProfile.id }}">Подробнее</router-link>
			</div>
		</div>
	</div>

</template>
<style lang="scss" scoped>
.card-img-top {
	object-fit: contain;
	height: 200px;
	border-radius: 10px
}
.card-text-height {
	min-height: 2.4em;
	line-height: 1.2em;
}
</style>
