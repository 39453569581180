<script>
import {Modal} from 'bootstrap';
import {axios, api} from '../App.vue';
import { useDictionaryStore } from '../stores/DictionaryStore.js';
import { useCurrentListPathStore } from '../stores/CurrentListPathStore.js';
	export default {
		setup() {
			const dictionaryStore = useDictionaryStore();
			const currentListPathStore = useCurrentListPathStore();
			return {
				dictionaryStore,
				currentListPathStore,
			}
		},
		props: ['profileId'],
		data(){
			return {
				profileData : {},
				msg: '',
				mail: '',
				msgModal: '',
				showSuccsess: false,
			}
		},
		methods: {
			ShowSuccess(){
				this.showSuccsess = true;
				setTimeout(() => {
				  this.showSuccsess = false;
				}, 5000);
			},
			GetProfile(){
				axios
					.get(api.baseURL + "/api/profiles/" + this.profileId)
					.then(response => {
						this.profileData = response.data;
				});
			},
			CheckMsgForm(event){
				event.preventDefault();
				this.SendMsg();
			},
			SendMsg() {
				let data = new FormData();
				data.append('user_id', this.profileId);
				data.append('msg', this.msg);
				data.append('mail', this.mail);
				axios
					.post(api.baseURL + "/send-msg/", data)
					.then(response => {
						console.log(response.data);
						this.msg = '';
						this.mail = '';
						this.msgModal.hide();
						if (response.data.code==200) {
							this.ShowSuccess();
						}
				});
			}
		},
		computed: {
			years: function(){
				var str = "более 10 лет";
				if (this.profileData['expirience'] == 1) {
				str = this.profileData['expirience'] + ' год';
				}
				if (this.profileData['expirience'] > 1 && this.profileData['expirience'] < 5) {
				str = this.profileData['expirience'] + ' года';
				}
				if (this.profileData['expirience'] == 0 || (this.profileData['expirience'] > 4 && this.profileData['expirience'] < 10)) {
				str = this.profileData['expirience'] + ' лет';
				}
			return str;
			},
			isValidEmail: function(){
			return String(this.mail)
				.toLowerCase()
				.match(
				  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);	
			},
		},
		mounted(){
			this.msgModal = new Modal(document.getElementById('messageModal'));
			this.GetProfile();
		}
	}
</script>
<template>
<div class="container">
	<div class="row">
		<div class="col-sm-6 col-md-4 col-md-3">
			<img :src="profileData.avatar" alt="" class="img-fluid rounded">
		</div>
		<div class="col mt-4 mt-sm-0">
			<div class="row">
				<div class="col-12 text-primary">
					<h1>{{ profileData.last_name }} {{ profileData.first_name }} {{ profileData.patronic }}</h1>
				</div>
			</div>
			<div class="row">
				<div class="col-12 my-2" v-if="profileData.hasOwnProperty('location')">
					<span class="fw-bold">Регион: </span>
					<span v-for="location, index in profileData.location">
						<span v-if="index>0">, </span><span>{{ dictionaryStore.GetTranslation('locations', location).title }}</span>
					</span>
				</div>
				<div class="col-12 my-2" v-if="profileData.hasOwnProperty('language')">
					<span class="fw-bold">Языки: </span>
					<span class="mx-1" v-for="lang in profileData.language.sort()">
						<img height="20" :src="dictionaryStore.dictionary.languages[dictionaryStore.GetIndex('languages', lang)].icon" :alt="dictionaryStore.GetTranslation('languages', lang-1).title">
					</span>
				</div>
				<div class="col-12 col-md-6" v-if="profileData.hasOwnProperty('social_media')">
					<span class="fw-bold">Социальные сети: </span>
					<span class="mx-1" v-for="media in profileData.social_media.sort()">
						<a :href="media.link"><img width="25" :src="dictionaryStore.dictionary.socialmedia[media.media_type-1].icon" alt=""></a>
					</span>
				</div>
			</div>
			<div class="row">
				<div class="col my-3">
					<a type="button" class="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#contactModal" href="#">
						Контакты
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<ul class="nav nav-tabs my-3" id="myTab" role="tablist">
				<li class="nav-item" role="presentation">
					<button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Образование</button>
				</li>
				<li class="nav-item" role="presentation" v-if="profileData.hasOwnProperty('publications') && profileData.publications.length > 0">
					<button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Публикации</button>
				</li>
			</ul>
			<div class="tab-content" id="myTabContent">
				<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
					<ul class="list-group" v-if="profileData.hasOwnProperty('education')">
						<li class="list-group-item" v-for="education in profileData.education.sort()">{{ dictionaryStore.GetTranslation('educations', education).title }}</li>
					</ul>
				</div>
				<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
					<div class="row"  v-if="profileData.hasOwnProperty('publications')">
						<div class="col-12 col-md-6 col-lg-4" v-for="publication in profileData.publications.sort()">
							<div class="d-flex flex-column justify-content-center align-items-center">
								<a target="_blank" :href="publication.file"><img class="img-fluid" :src="publication.preview" alt=""></a>
								<a target="_blank" :href="publication.file"><span>{{ publication.description }}</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-5">
		<div class="col">
			<h4><span class="fw-bold text-primary">Опыт: </span><span class=" text-primary">{{years}}</span></h4>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<ul class="nav nav-tabs my-3" id="experience" role="tablist">
				<li class="nav-item" role="exp">
					<button class="nav-link active" id="activity-tab" data-bs-toggle="tab" data-bs-target="#activity" type="button" role="tab" aria-controls="activity" aria-selected="true">Области деятельности</button>
				</li>
				<li class="nav-item" role="exp" v-if="profileData.hasOwnProperty('media_expirience') && profileData.media_expirience.length > 0">
					<button class="nav-link" id="media-tab" data-bs-toggle="tab" data-bs-target="#media" type="button" role="tab" aria-controls="media" aria-selected="false">СМИ</button>
				</li>
			</ul>
			<div class="tab-content" id="experienceTabContent">
				<div class="tab-pane fade show active" id="activity" role="tabpanel" aria-labelledby="activity-tab">
					<ul class="list-group">
						<li class="list-group-item" v-for="fow in profileData.fields_of_work">
							<span>{{ dictionaryStore.GetTranslation('fieldsofwork', fow.field_of_work_type).title }}</span>
							<div class="progress">
								<div class="progress-bar" role="progressbar" :style="'width: '+fow.estimate*20+'%;'" :aria-valuenow="fow.estimate" aria-valuemin="0" aria-valuemax="5"><span class="fw-bold fs-5">{{ fow.estimate }}</span></div>
							</div>
						</li>
					</ul>
				</div>
				<div class="tab-pane fade" id="media" role="tabpanel" aria-labelledby="media-tab">
					<ul class="list-group" v-if="profileData.media_expirience">
						<li class="list-group-item" v-for="media in profileData.media_expirience">{{ dictionaryStore.GetTranslation('mediaexpirience', media).title }}</li>
						
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-5 mb-5">
		<div class="col">
			 <a type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#messageModal" href="#" >
				Написать сообщение
			</a>
		</div>
		<div class="col-auto align-self-end">
			<a type="button" class="btn btn-secondary" :href="'/profiles/?' + currentListPathStore.path">
				Вернуться к списку
			</a>
		</div>
	</div>
	<div style="margin-bottom: 100px;"></div>
</div>


<!-- Modal -->
<div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Контакты</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<ul class="list-group">
					<li class="list-group-item">
						<div class="row">
							<div class="col-12 col-sm-6 d-flex flex-column align-self-start" v-if="!profileData.is_hide_messengers">
								<span class="fw-bold">Телефон: </span>
								<div v-for="messenger in profileData.messengers">
									<img height="25" :src="dictionaryStore.dictionary.messengers[messenger.messenger_type-1].icon" class="mx-1"><span >{{ messenger.number }}</span>
								</div>
							</div>
							<div class="col-12 col-sm-6 d-flex flex-column align-self-start" v-if="!profileData.is_hide_email">
								<span class="fw-bold">Электронная почта:</span>
								<div v-for="email in profileData.contact_emails">
									<a :href="'mailto:'+email.address" target="_blank">{{ email.title }}</a>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="messageModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Отправить сообщение</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<form action="" @submit="CheckMsgForm">
					<div class="mb-3">
						<label for="text_input" class="form-label">Ваше сообщение</label>
						<textarea class="form-control" name="msg" placeholder="Сообщение" id="text_input" v-model="msg" required></textarea>
						<div class="invalid-feedback">
						Поле не должно быть пустым
						</div>
					</div>
					<div class="mb-3">
						<label for="exampleInputEmail1" class="form-label">Ваш электронная почта</label>
						<input type="email" class="form-control" name="mail"  id="exampleInputEmail1" aria-describedby="emailHelp" v-model="mail" required>
						<div class="invalid-feedback">
						Поле не должно быть пустым
						</div>
					</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-primary">Отправить</button>
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
			</div>
				</form>
			</div>
		</div>
	</div>
</div>
<div class="alert alert-success d-flex align-items-center position-fixed top-0 start-0" role="alert" v-if="showSuccsess">
  <div class="text-center">
    Ваше сообщение отправлено.
  </div>
</div>
</template>
<style lang="scss" scoped>
</style>