<script>
import {axios, api} from '../App.vue';
import { useAuthStore } from '../stores/AuthStore.js';

export default {
	setup() {
		const authStore = useAuthStore();

		return {
			authStore,

		}
	},
	data() {
		return {
			log: "",
			pwd: "",
			rememberMe: false
		}
	},
	mounted(){
		
	},
	methods: {
		Submit(){
			let data = new FormData();
			data.append('username', this.log);
			data.append('password', this.pwd);
			axios
				.post(api.baseURL + "/api-token-auth/",data)
				.then(response => {
					console.log(response.data.token);
					this.authStore.Login(response.data.token,this.rememberMe);
					this.$router.replace("/1");
			});

		},
	}
}
</script>

<template>
	<main>
		<div class="login-form">
			<div class="col-6">
				<form>
					<div class="mb-3">
						<label for="exampleInputEmail1" class="form-label">Email address</label>
						<input v-model="log" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
						<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
					</div>
					<div class="mb-3">
						<label for="exampleInputPassword1" class="form-label">Password</label>
						<input v-model="pwd" type="password" class="form-control" id="exampleInputPassword1">
					</div>
					<div class="mb-3 form-check">
						<input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="rememberMe">
						<label class="form-check-label" for="exampleCheck1">Remember me</label>
					</div>
					<button class="btn btn-primary mr" @click.prevent="Submit()">Submit</button>
				</form>			
			</div>
		</div>
		
	</main>
</template>
<style lang="scss">
.login-form {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.mr{
	margin-right: 5px;
}

</style>
