import { defineStore } from 'pinia'
import CryptoJS from 'crypto-js';
import {axios} from '../App.vue';
let TokenEncryptionKey = "4IYHTlQLn9";
let expiryTime = 72;//hours

export const useAuthStore = defineStore({
	id: 'AuthStore',
	state: () => ({
		token: '',

	}),
	getters: {
		isLoggedIn: (state) => {
			return true;
			//return state.token != '';
		},

	},
	actions: {
		Login(_token, rememberMe) {
			Logout(this);
			Login(this, _token);
			if(rememberMe){
				StoreToken(_token);
			}

		},
		TryReadToken(){
			let cookieToken = CryptoJS.AES.decrypt(GetCookieValueByRegEx("token"),TokenEncryptionKey).toString(CryptoJS.enc.Utf8);
			if(cookieToken != ''){
				Login(this,cookieToken);

				//Restoring the token with the curren date
				StoreToken(cookieToken);
			}
		},
		Logout(){
			Logout(this);
		}
	}
})
function StoreToken(token) {
	let cookieTime = new Date();
	let time = cookieTime.getTime() + 3600*1000*expiryTime;
	cookieTime.setTime(time);

	let encrToken = CryptoJS.AES.encrypt(token, TokenEncryptionKey).toString();
	document.cookie = "token=" + encrToken + ";expires=" + cookieTime+ ";path=/";
}
function Login(state, token){
	state.token = token;
	axios.defaults.headers.common['Authorization'] = token;
}
function Logout(state){
	state.token = '';
	axios.defaults.headers.common['Authorization'] = '';
	document.cookie = "token=;path=/";
}

//https://jsben.ch/AhMN6
function GetCookieValueByRegEx(a, b) {
	b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
	return b ? b.pop() : '';
}