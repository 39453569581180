<script setup>
import ProfileList from "../components/ProfileList.vue"
import ProfileFilters from "../components/ProfileFilters.vue"
import {axios, api} from '../App.vue';
import { useDictionaryStore } from '../stores/DictionaryStore.js';
const dictionaryStore = useDictionaryStore();



</script>
<script>
export default {
	data() {
		return {
		}
	},
	mounted(){
		if (Object.keys(this.dictionaryStore.dictionary).length === 0){
			this.dictionaryStore.GetDictionaryData();		
		}
	},
	computed: {
		pathParams: function (){
			return new URLSearchParams(this.$route.fullPath.split('?',2)[1]);
		}
	},
	methods: {
	}
}
</script>
<template>
	<main>
		<ProfileFilters :filter-query="pathParams"></ProfileFilters>
		<div class="row mb-3 top-header">
			<div class="col-1"></div>
			<div class="col-3 text-left">
				<a href="/"><img src="../assets/images/logo.svg" class="img-fluid" alt=""></a>				
			</div>
			<div class="col-2"></div>
			<!-- <div class="col-6 text-end">
				<img src="../assets/images/decor-right.png" class="img-fluid" alt="">
			</div> -->
		</div>
		<div class="display">
			<ProfileList :filter-query="pathParams"></ProfileList>
		</div>
		
	</main>
</template>

<style lang="scss">
.top-header{
	--bs-gutter-x: 0;
}
.profile-list {
	&__filters {
		
	}
	&__list-wrapper {

		flex-grow: 1;
		overflow: auto;
	}
	&__list{
		padding: 30px 20px;
		//height: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

</style>
