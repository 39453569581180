import { defineStore } from 'pinia'
import {axios, api} from '../App.vue';

export const useDictionaryStore = defineStore({
	id: 'DictionaryStore',
	state: () => ({
		dictionary: {},
		selectedLanguage: 'ru',
		defaultLanguage: 'en',

	}),
	getters: {
	},
	actions: {
		GetIndex(dict, index){
			let res = null;
			this.dictionary[dict].forEach((el, idx) => {
				if (el.id == index){
					res = idx;
				}
			});
			return res;						
		},
		GetTranslation(dict, id){
			let index = this.GetIndex(dict, id);
			if (index != null){
				if (this.selectedLanguage in this.dictionary[dict][index]['translations']){
					return this.dictionary[dict][index]['translations'][this.selectedLanguage]
				} else {
					return this.dictionary[dict][index]['translations'][this.defaultLanguage]
				}
			} else {
				return {"title":"Не задано"};
			}

		},
		GetDictionaryData() {
			GetDictionaryData(this);
			}

		},
})
function GetDictionaryData(state){
	axios
		.get(api.baseURL + "/api/messengers/")
		.then(response => {
			state.dictionary.messengers = response.data.results;
	});
	axios
		.get(api.baseURL + "/api/socialmedia/")
		.then(response => {
			state.dictionary.socialmedia = response.data.results;
	});
	axios
		.get(api.baseURL + "/api/fieldsofwork/")
		.then(response => {
			state.dictionary.fieldsofwork = response.data.results;
	});
	axios
		.get(api.baseURL + "/api/locations/")
		.then(response => {
			state.dictionary.locations = response.data.results;
	});
	axios
		.get(api.baseURL + "/api/languages/")
		.then(response => {
			state.dictionary.languages = response.data.results;
	});
	axios
		.get(api.baseURL + "/api/educations/")
		.then(response => {
			state.dictionary.educations = response.data.results;
	});
	axios
		.get(api.baseURL + "/api/genders/")
		.then(response => {
			state.dictionary.genders = response.data.results;
	});
	axios
		.get(api.baseURL + "/api/mediaexpirience/")
		.then(response => {
			console.log(response.data);
			state.dictionary.mediaexpirience = response.data.results;
	});
}