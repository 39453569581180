<script setup>
import InitialScreenFilters from "../components/InitialScreenFilters.vue"
// import {axios, api} from '../App.vue';
import { useDictionaryStore } from '../stores/DictionaryStore.js';
const dictionaryStore = useDictionaryStore();
</script>
<script>
export default {
	data() {
		return {
		}
	},
	mounted(){
		if (Object.keys(this.dictionaryStore.dictionary).length === 0){
			this.dictionaryStore.GetDictionaryData();		
		}
	},
	methods: {
	}
}
</script>
<template>
	<div class="decor">
		<!-- <div class="decor__left">
			<img src="../assets/images/decor-left.png" alt="">
		</div> -->
		<div class="decor__logo">
				<img src="../assets/images/logo.svg" class="img-fluid" alt="">
		</div>
		<!-- <div class="decor__right">
				<img src="../assets/images/decor-right.png" class="img-fluid" alt="">
		</div> -->
	</div>
	<main class="main">
		<div class="container">
			<div class="main__title mx-3"><h1 class="h1">База данных гендерных экспертов</h1></div>
			<div class="main__text">
				<p>База данных эксперток и экспертов по гендерным вопросам создана с целью расширения взаимодействия журналистов с ними,   обеспечения адекватной репрезентации экспертного гендерного взгляда в материалах СМИ и предоставления общественности качественной информации по гендерным вопросам.  </p>
				<p>Экспертки и эксперты по гендерным вопросам могут попасть в Базу данных, заполненив анкету на русском или кыргызском языках:</p>
				<div class="row justify-content-center">
					<div class="col-12 col-md-3"><a target="_blank" href="https://forms.gle/YfrT2b1tBDmgJSw78" class="btn btn-primary">Анкета на русском языке</a></div>
					<div class="col-12 col-md-3"><a target="_blank" href="https://forms.gle/BJvWCe4UCzeUFGki7" class="btn btn-primary">Анкета на кыргызском языке</a></div>
				</div>
			</div>
		</div>
		<div class="container my-5">
			<div class="row">
				<InitialScreenFilters></InitialScreenFilters>
			</div>
		</div>
	</main>
</template>

<style lang="scss">
.decor {
	display: flex;
	justify-content: center;
	&__left {
		flex: 0 0 10vw;
		@media screen and (max-width: 700px) {
			flex: 0 0 20vw;
		}
		img {
			width: 100%;
		}
	}
	&__logo {
		margin: 20px 10px;
		flex: 0 0 20vw;
		@media screen and (max-width: 700px) {
			flex: 0 0 40vw;
		}
		img {
			width: 100%;
		}

	}
	&__right {
		display: flex;
		flex-direction: column;
		img {
			max-height: 10vh;
		}
	}
	&__right-top {
	}
	&__right-bottom {
		margin-top: 5%;
		text-align: center;
		align-self: start;
		img {
			max-width: 30%;
			@media screen and (max-width: 700px) {
				min-width: 60%;
			}
		}
	}
}
.main {
	&__title {
		color: #008138;
		text-align: center;
	}
}

.profile-list {
	&__filters {
		
	}
	&__list-wrapper {

		flex-grow: 1;
		overflow: auto;
	}
	&__list{
		padding: 30px 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

</style>
