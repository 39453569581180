<script>
	import axios from 'axios';
	let api = {};
	api.baseURL = "https://expert.hero-datkayim.kg";
	//const axios = require(["axios"]).default;
	export {axios, api};	
</script>
<script setup>
import { RouterLink, RouterView } from 'vue-router'
import 'bootstrap';
import { useAuthStore } from './stores/AuthStore.js';
const authStore = useAuthStore();

import HeaderBar from './components/HeaderBar.vue'

</script>

<template>
	<div class="app__navbar">
		<!-- <HeaderBar v-if="this.authStore.isLoggedIn">
		</HeaderBar> -->
	</div>
	<div class="app__content">
		<RouterView />
	</div>
	
</template>

<style lang="scss">
$primary: #008138;/*#264b91;*/

@import "bootstrap";
//@import 'bootstrap/dist/css/bootstrap.min.css';
@import './assets/css/base.css';
/* @import '@/assets/bootstrap/css/bootstrap.css'; */
*{
	position: static;
}
.app{
	height: 100vh;
	//min-height: 100vh;
	display: flex;
	flex-direction: column;
	
	&__navbar{

	}
	&__content{
		flex: 0 0 100%;
		max-height: 100%;
		main{
			max-height: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
		}
		position: relative;
	}
}
.display{ // use to display scrollable content
	// position: absolute;
	width: 100%;
	flex-grow: 1;
	// height: 100%;
	overflow: auto;

	&::-webkit-scrollbar {
		width:10px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		// background: #f1f1f1; 
	}
	&::-webkit-scrollbar-thumb {
		background: #888; 
	    border-radius:5px;
	    border: 2px solid transparent;
  		background-clip: content-box;
		transition: all 0.5s;
  		
  		&:hover {
		  	background: #555;
		    border-radius:5px;
		    border: 2px solid transparent;
	  		background-clip: content-box;	
  		}
	}
}
</style>
